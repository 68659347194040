import {Component} from "react";

class FirstComponent extends Component{
    render() {
        const {children, color} = this.props
        return (
            <div style={ {color: color}  }>
                {children}
            </div>
        )
    }
}

export default FirstComponent;