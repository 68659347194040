import {Component} from "react";

class SecondComponent extends Component{
    render() {
        const {fontSize, children} = this.props;
        return(
           <div style={{fontSize: fontSize}}>
               {children}
           </div>
        )
    }
}

export default SecondComponent;