import './App.css';
import Border from "./Components/Border/Border";
import FirstComponent from "./Components/FirstComponent/FirstComponent";
import SecondComponent from "./Components/SecondComponent/SecondComponent";

function App() {
  return (
    <div className="App">
      <Border title = {"Primeiro Teste"}>
          <FirstComponent color = "red">
            <p>Primeiro componente</p>
          </FirstComponent>
      </Border>
        <Border title = {"Segundo Teste"}>
            <SecondComponent fontSize={"2rem"}>
                <p>Segundo componente</p>
            </SecondComponent>
        </Border>
    </div>
  );
}

export default App;
