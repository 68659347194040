import {Component} from "react";
import "./border.css"
class Border extends Component {

    render(){
        const {title ,children} = this.props;
        return (
            <div className={"Border"}>
                <h1>{title}</h1>
                <div>{children}</div>
            </div>
        )
    }
}

export default Border;